import { userQueries } from '@repo/db/queries/user'
import { Outlet, redirect, useRouteError } from 'react-router'
import { ErrorCard } from '~/components/ErrorCard'
import { Layout } from '~/components/layout/Layout'
import { useIntercom } from '~/hooks/useIntercom'
import { requireAuth } from '~/services/auth/auth.server'
import type { Route } from './+types/route'
import { getOrgTags } from './get-org-tags.server'

export const loader = async ({ request }: Route.LoaderArgs) => {
  const auth = await requireAuth(request)
  if (!auth.isOnboarded) throw redirect('/onboarding/survey')
  const [memberships, orgTags] = await Promise.all([
    userQueries.getMemberships(auth.userId),
    getOrgTags(auth.orgId),
  ])
  if (!memberships.length) throw redirect('/org')
  return { memberships, orgTags }
}

export default function AppLayoutRoute() {
  useIntercom()

  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}

export function ErrorBoundary() {
  const error = useRouteError()

  return (
    <Layout>
      <div className="flex h-full flex-col items-center justify-center">
        <ErrorCard error={error} />
      </div>
    </Layout>
  )
}
